import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectorRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import type { Subscription } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Feature } from './feature-flags.model';
import { FeatureFlagsService } from './feature-flags.service';

/**
 * Mark an element as related to a feature
 */
@Directive({ selector: '[flFeature]' })
export class FeatureFlagsDirective implements OnDestroy, OnInit {
  private featureChanges$ = new ReplaySubject<Feature>(1);
  private elseTemplateRef: TemplateRef<any>;
  private subscriptions: Subscription[] = [];

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private featureFlagService: FeatureFlagsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.featureChanges$
        .pipe(
          switchMap(feature => this.featureFlagService.getFlag(feature)),
          distinctUntilChanged(),
        )
        .subscribe(flagState => {
          if (flagState) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else if (this.elseTemplateRef) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.elseTemplateRef);
          } else {
            this.viewContainer.clear();
          }
          this.changeDetectorRef.markForCheck();
        }),
    );
  }

  @Input() set flFeature(feature: Feature) {
    this.featureChanges$.next(feature);
  }

  @Input() set flFeatureElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef = templateRef;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
